import PropTypes from 'prop-types';
import styles from './BlockHeading.module.scss';

/**
 * Renders a heading block
 *
 * @param  {object}  props         component props
 * @param  {string}  props.level   size of heading
 * @param  {string}  props.content html content for heading
 * @return {Element}               heading element
 */

const LEVELS = [1,2,3,4,5,6]
export default function BlockHeading({ level, content, customStyle={} }) {
  const formatHeading = () => ({ __html: content });
  const isPresent = LEVELS.indexOf(level)
  const CustomHeaderTag = isPresent!==-1 ? `h${level}`:'h2';

  return <CustomHeaderTag className={styles[`heading${level}`]} dangerouslySetInnerHTML={formatHeading()} style={customStyle}/>;
}

BlockHeading.propTypes = {
  level: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
};
