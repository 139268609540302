import PropTypes from 'prop-types';
import Blocks from '@/components/Blocks';
import styles from './BlockButton.module.scss';

/**
 * Renders an button block
 *
 * @param  {object}  props              component props
 * @param  {string}  props.anchor       optional id selector
 * @param  {string}  props.innerBlocks  array of innerblocks from gutenberg
 * @return {Element}                    buttons element
 */
export default function BlockButtons({ anchor, innerBlocks }) {
  return (
    <div id={anchor} className={styles.button__list}>
      {!!innerBlocks?.length && <Blocks blocks={innerBlocks} />}
    </div>
  )
};

BlockButtons.propTypes = {
  anchor: PropTypes.string,
  innerBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      attributes: PropTypes.object
    })
  )
};

BlockButtons.defaultProps = {
  anchor: null,
};