import PropTypes from 'prop-types';
import style from './BlockTable.module.scss';

/**
 * Renders an table block
 *
 * @param  {object}  props      component props
 * @param  {string}  props.body table values
 * @return {Element}            table element
 */

export default function BlockTable({ body }) {
  const formatTable = (content) => ({ __html: content });
  const renderHeading = () =>
    body[0].cells.map((col) => (
      <th
        key={`header-${col.content}`}
        style={{ textAlign: 'left' }}
        dangerouslySetInnerHTML={formatTable(col.content)}
      />
    ));

  const renderBody = () =>
    body.map((item, i) => {
      if (i > 0)
        return (
          <tr key={`${i}-row`}>
            {item.cells.map((col, j) => (
              <td key={`${item[j]}-${col.content}`} dangerouslySetInnerHTML={formatTable(col.content)} />
            ))}
          </tr>
        );
    });
  return (
    <table className={style.blockTable}>
      <thead>
        <tr>{renderHeading()}</tr>
      </thead>
      <tbody>{renderBody()}</tbody>
    </table>
  );
}

BlockTable.propTypes = {
  body: PropTypes.array.isRequired,
};
