import PropTypes from 'prop-types';
import style from './BlockParagraph.module.scss';

/**
 *
 * @param  {string}  content Paragraph content
 * @return {Element}         paragraph element
 */
export default function BlockParagraph({ content, customStyle,className }) {
  const customClassName = `${style.paragraph} ${className?style[className]:''}`

  const formatParagraph = () => ({ __html: content });
  return <p className={customClassName} dangerouslySetInnerHTML={formatParagraph()} style={customStyle} />;
}

BlockParagraph.propTypes = {
  content: PropTypes.string.isRequired,
};
