import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import BlockArticleCard from '@/components/BlockArticleCard';
import getLatest from '@/lib/wp/content/getLatestNodes';

/**
 * LatestList element for a filtered list component
 *
 * @return {Element} LatestList element
 */
export default function LatestList({ numberOfPosts, initialPosts }) {
  let data; 
  let loading; 
  let error;

  const query = getLatest;
  const variables = { first: numberOfPosts };

  if(initialPosts && Object.keys(initialPosts).length) {
    loading = false;
    error = false;
    data = initialPosts?.data;
  } else {
    const queryData = useQuery(query, { variables });
    loading = queryData.loading;
    error = queryData.error;
    data = queryData.data;
  }


  // const { data, loading, error } = useQuery(query, { variables });

  if (loading) {
    return <div>Loading data for latest nodes...</div>;
  }

  if (error) {
    return <div>Error: {JSON.stringify(error.message)}</div>;
  }

  return (
    <>
      {data?.contentNodes?.edges?.map((edge) => {
        const postID = edge?.node?.databaseId;
        const postType = edge?.node?.contentType?.node?.graphqlSingleName;
        return (
          <BlockArticleCard
            key={postID}
            postID={postID}
            postType={postType}
            cardType="condensed"
            columnSpan={6}
            postData={edge?.node}
          />
        );
      })}
    </>
  );
}

LatestList.propTypes = { numberOfPosts: PropTypes.number.isRequired, initialPosts: PropTypes.array };
