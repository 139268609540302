import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import BlockArticleCard from '@/components/BlockArticleCard';
import queryByTag from '@/lib/wp/content/getPostByTag';
import queryByDomain from '@/lib/wp/content/getPostByDomain';
import queryByCompanyType from '@/lib/wp/content/getPostByCompanyType';
import queryByJobFunction from '@/lib/wp/content/getPostByJobFunction';
import queryByJobLevel from '@/lib/wp/content/getPostByJobLevel';
import queryByTopic from '@/lib/wp/content/getPostByTopic';
import queryByEventCategory from '@/lib/wp/content/getPostByEventCategory';
import queryByArticleCategory from '@/lib/wp/content/getPostByArticleCategory';
import queryVideosByCategory from '@/lib/wp/content/getPostByVideoCategory';

/**
 * TaxonomyList element for a filtered list component
 *
 * @param  {object}  props          component attributes
 * @param  {string}  props.taxonomy taxonomy library to pull from
 * @param  {object}  props.filter   term used for filter
 * @return {Element}                TaxonomyList element
 */
export default function TaxonomyList({ taxonomy, filter, tax, numberOfPosts }) {
  const queryMap = {
    tags: queryByTag,
    hdm_tax_domain: queryByDomain,
    hdm_tax_company_type: queryByCompanyType,
    hdm_tax_job_function: queryByJobFunction,
    hdm_tax_job_level: queryByJobLevel,
    hdm_tax_topic: queryByTopic,
    hdm_cat_article: queryByArticleCategory,
    hdm_cat_event: queryByEventCategory,
    hdm_cat_video: queryVideosByCategory,
  };
  const query = queryMap[taxonomy];
  const variables = { first: numberOfPosts, id: filter.slug };
  const { data, loading, error } = useQuery(query, { variables });

  if (loading) {
    return <div>Loading data for {filter?.name} nodes...</div>;
  }

  if (error) {
    return <div>Error: {JSON.stringify(error.message)}</div>;
  }

  return (
    <>
      {data?.[tax]?.contentNodes?.edges?.map((edge) => {
        const postID = edge?.node?.databaseId;
        const postType = edge?.node?.contentType?.node?.graphqlSingleName;
        return (
          <BlockArticleCard
            postID={postID}
            postType={postType}
            cardType="condensed"
            columnSpan={6}
            postData={edge?.node}
          />
        );
      })}
    </>
  );
}

TaxonomyList.propTypes = {
  taxonomy: PropTypes.string.isRequired,
  filter: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }).isRequired,
  numberOfPosts: PropTypes.number.isRequired,
};
