import PropTypes from 'prop-types';
import cn from 'classnames';
import Container from '@/components/Container';
import Blocks from '@/components/Blocks';
import { useState, useEffect } from 'react';
import styles from './BlockLanding.module.scss';

/**
 * Renders a grid display of article cards
 *
 * @param  {object}  props             component props
 * @param  {Array}   props.innerBlocks array of innerblocks from gutenberg
 * @return {Element}                   Landing element
 */
export default function BlockLanding({ innerBlocks }) {
  const [cardBlocks, setCardBlocks] = useState(null);
  const [filterBlock, setFilterBlock] = useState(null);
  useEffect(() => {
    if (innerBlocks) {
      let cards = [];
      let filter = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = innerBlocks.length; i < len; i++) {
        const block = innerBlocks[i];
        if (block.name === 'hdm/filtered-list') {
          filter = [block];
        } else {
          const newBlock = {
            ...block,
            cardType: i === 0 ? 'feature-medium' : 'inline',
            columnSpan: 5,
          };
          cards = [...cards, newBlock];
        }
      }
      setCardBlocks(cards);
      setFilterBlock(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className={cn(styles.landing_wrapper)}>
      <div className={cn(styles.card_holder, styles.home_landing)}>
        <Blocks blocks={cardBlocks} />
      </div>
      <div className={styles.filter_holder}>
        <Blocks blocks={filterBlock} />
      </div>
    </Container>
  );
}

BlockLanding.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  innerBlocks: PropTypes.array,
};

BlockLanding.defaultProps = {
  innerBlocks: [],
};
