import { gql } from '@apollo/client';
import postCardFields from '@/lib/wp/_partials/postCardFields';

const getLatest = gql`
  query GET_LATEST_POSTS($first: Int, $orderBy: PostObjectsConnectionOrderbyEnum = DATE, $order: OrderEnum = DESC, $imageSize: MediaItemSizeEnum = LARGE) {
    contentNodes(
      first: $first
      where: {
        contentTypes: [POST, HDM_AUDIO, HDM_EVENTS, HDM_PODCASTS, HDM_VIDEOS]
        orderby: { field: $orderBy, order: $order }
      }
    ) {
      edges {
        node {
          contentType {
            node {
              graphqlSingleName
            }
          }
          ... on Post {
            ${postCardFields}
          }
          ... on Event {
            ${postCardFields}
          }
          ... on Audio {
            ${postCardFields}
          }
          ... on Video {
            ${postCardFields}
          }
        }
      }
    }
  }
`;

export default getLatest;
