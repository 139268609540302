import cn from 'classnames';
import PropTypes from 'prop-types';
import Container from '@/components/Container';
import TaxonomyList from './TaxonomyList';
import Small from './variations/Small';
import Medium from './variations/Medium';
import Full from './variations/Full';
import styles from './BlockTopicCluster.module.scss';

/**
 * Renders a horizontal display of article cards
 *
 * @param  {object}  props                      component props
 * @param  {number}  props.columns              number of items to display
 * @param  {string}  props.title                section title
 * @param  {object}  props.topic                contains the taxonomy name and the term name/slug
 * @param  {string}  props.featureType          type of feature display
 * @param  {string}  props.colorScheme          color scheme for display
 * @param  {string}  props.accentColorOverride  optional color override for drop shadow
 * @return {Element}                            Article Columns element
 */
export default function BlockTopicCluster({ columns, featureType, title, topic, colorScheme, manualSelect, innerBlocks, accentColorOverride }) {
  const taxonomyMap = {
    tags: 'tag',
    hdm_tax_domain: 'domain',
    hdm_tax_company_type: 'companytype',
    hdm_tax_job_function: 'jobfunction',
    hdm_tax_job_level: 'joblevel',
    hdm_tax_topic: 'topic',
    hdm_cat_article: 'articlecategory',
    hdm_cat_event: 'eventcategory',
    hdm_cat_video: 'videocategory',
  };

  const colorMap = {
    white: '',
    wheat: styles['article__wrapper--wheat'],
    gray: styles['article__wrapper--gray'],
    green: styles['article__wrapper--green'],
  }

  const backgroundClass = colorScheme === 'white' ? '' : styles['article__wrapper--background'];

  return (
    <div className={cn(styles.article__wrapper, backgroundClass, colorMap[colorScheme])}>
      <Container className={cn(styles.article__cards, `columns-${columns}`)}>
          <div className={styles.article__cards_title}>
            {title && <h2>{title}</h2>}
            {/* {topic?.taxonomy && <a href={`/${taxonomyMap[topic?.taxonomy]}/${topic?.term?.slug}`}>See All</a>} */}
          </div>
          {manualSelect && featureType === 'small' && (
            <Small
              columns={columns}
              colorScheme={colorScheme}
              isManual
              innerBlocks={innerBlocks}
              accentColorOverride={accentColorOverride}
            />
          )}
          {manualSelect && featureType === 'medium' && (
            <Medium
              columns={columns}
              colorScheme={colorScheme}
              isManual
              innerBlocks={innerBlocks}
              accentColorOverride={accentColorOverride}
            />
          )}
          {manualSelect && featureType === 'full' && (
            <Full
              columns={columns}
              colorScheme={colorScheme}
              isManual
              innerBlocks={innerBlocks}
              accentColorOverride={accentColorOverride}
            />
          )}
          {!manualSelect && (
            <TaxonomyList
              topic={topic}
              columns={columns}
              featureType={featureType}
              colorScheme={colorScheme}
              tax={taxonomyMap[topic?.taxonomy]}
              accentColorOverride={accentColorOverride}
            />
          )}
      </Container>
    </div>
  );
}

BlockTopicCluster.propTypes = {
  columns: PropTypes.number,
  title: PropTypes.string,
  topic: PropTypes.shape({
    taxonomy: PropTypes.string,
    term: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  }),
  featureType: PropTypes.string,
  colorScheme: PropTypes.string,
  accentColorOverride: PropTypes.string,
};

BlockTopicCluster.defaultProps = {
  columns: 3,
  title: '',
  topic: {
    taxonomy: '',
    term: {
      name: '',
      slug: '',
    },
  },
  featureType: 'medium',
  colorScheme: 'white',
  accentColorOverride: '',
};
