import PropTypes from 'prop-types';
import styles from './BlockFreeform.module.scss';

/**
 * Renders a freeform element - used mostly for legacy content
 *
 * @param  {string}  content Freeform content
 * @return {Element}         Freeform element
 */
export default function BlockFreeform({ content }) {
  // eslint-disable-next-line func-style
  const formatContent = () => ({ __html: content });
  // eslint-disable-next-line react/no-danger
  return <div className={styles.general_content} dangerouslySetInnerHTML={formatContent()} />;
}

BlockFreeform.propTypes = {
  content: PropTypes.string.isRequired,
};
