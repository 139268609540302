import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import BlockArticleCard from '@/components/BlockArticleCard';
import Blocks from '@/components/Blocks';
import styles from '../BlockTopicCluster.module.scss';

/**
 * Render Full variation of Topic Cluster
 *
 * @param  {object}  props                      component attributes
 * @param  {object}  props.data                 wp data
 * @param  {number}  props.columns              number of columns to display
 * @param  {string}  props.colorScheme          color scheme for display
 * @param            props.isManual
 * @param            props.innerBlocks
 * @param  {string}  props.accentColorOverride  optional color override for drop shadow
 * @return {Element}                            Full topic cluster
 */
export default function Full({ data, columns, colorScheme, isManual = false, innerBlocks, tax, accentColorOverride }) {
  const [cardType, setCardType] = useState(null);
  const [cardBlocks, setCardBlocks] = useState(null);

  useEffect(() => {
    if (isManual && innerBlocks) {
      const newBlocks = innerBlocks
        .filter((item) => !!item.attributes.postID || !!item.attributes.postData)
        .map((block, index) => ({
          ...block,
          cardType: index === 0 ? 'feature-full' : cardType,
          columnSpan: columns,
          attributes:{ ...block.attributes,
            accentColorOverride: accentColorOverride ? accentColorOverride : ""},
          whiteBackground: index === 0 ? colorScheme === 'green' || colorScheme === 'wheat' : false,
          lightText: index === 0 ? false : colorScheme === 'green',
        }));
      setCardBlocks(newBlocks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardType]);

  useEffect(() => {
    /**
     * Sets card type based on screen size
     *
     * @param {event} e matchMedia change event
     */
    function setCard(e) {
      const mobileView = !e.matches;
      if (mobileView) {
        setCardType('inline');
      } else {
        setCardType('small-row');
      }
    }

    const mql = window?.matchMedia('(min-width: 913px)');
    mql.addEventListener('change', setCard);
    setCard(mql);
  }, []);

  if (isManual && !!cardBlocks) {
    return (
      <>
        <div className={styles.card__feature_full}>
          <Blocks blocks={[cardBlocks[0]]} />
        </div>
        <div className={styles.card__list_full}>
          <Blocks blocks={cardBlocks.slice(1)} />
        </div>
      </>
    );
  }

  if (!isManual) {
    return (
      <>
        <div className={styles.card__feature_full}>
          <BlockArticleCard
            postID={data?.[tax]?.contentNodes?.edges?.[0]?.node?.databaseId}
            postType={data?.[tax]?.contentNodes?.edges?.[0]?.node?.contentType?.node?.graphqlSingleName}
            postData={data?.[tax]?.contentNodes?.edges?.[0]?.node ?? {}}
            cardType="feature-full"
            columnSpan={columns}
            whiteBackground={colorScheme === 'green' || colorScheme === 'wheat'}
            accentColorOverride={accentColorOverride}
          />
        </div>
        <div className={styles.card__list_full}>
          {data?.[tax]?.contentNodes?.edges?.slice(1)?.map((edge) => {
            const postID = edge?.node?.databaseId;
            const postType = edge?.node?.contentType?.node?.graphqlSingleName;
            return (
              <BlockArticleCard
                key={postID}
                postID={postID}
                postType={postType}
                cardType={cardType}
                columnSpan={columns}
                lightText={colorScheme === 'green'}
              />
            );
          })}
        </div>
      </>
    );
  }

  return null;
}

Full.propTypes = {
  data: PropTypes.shape({
    tag: PropTypes.shape({
      contentNodes: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              databaseId: PropTypes.number,
              contentType: PropTypes.shape({
                node: PropTypes.shape({
                  graphqlSingleName: PropTypes.string,
                }),
              }),
            }),
          })
        ),
      }),
    }),
  }).isRequired,
  columns: PropTypes.number,
  colorScheme: PropTypes.string,
  accentColorOverride: PropTypes.string,
};

Full.defaultProps = {
  columns: 5,
  colorScheme: 'white',
  accentColorOverride: '',
};
