import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useQuery } from '@apollo/client';
import getVideoById from '@/lib/wp/content/getVideoById';
import styles from './BlockFeaturedVideo.module.scss';
import Container from '../Container';
import PlayButton from './PlayButton';

export default function BlockFeaturedVideo({ postID, colorScheme, initialContent }) {
  const query = getVideoById;
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const videoRef = useRef(null)
  let loading, error, data;

  if (initialContent && Object.keys(initialContent).length) {
    data = initialContent.data;
    error = false;
    loading = false;
  } else {
    let queryData = useQuery(query, { variables: { id: postID } });
    data = queryData.data;
    error = queryData.error;
    loading = queryData.loading;
  }

  const colorMap = {
    white: '',
    wheat: styles['article__wrapper--wheat'],
    gray: styles['article__wrapper--gray'],
    green: styles['article__wrapper--green'],
  };

  const backgroundClass = colorScheme === 'white' ? '' : styles['article__wrapper--background'];

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setVideoIsPlaying(true);
    }
  };

  const handlePause = () => {
    setVideoIsPlaying(false);
  }

  return (
    <div className={cn(styles.article__wrapper, backgroundClass, colorMap[colorScheme])}>
      <Container>
        {loading && (
          <h1>Loading...</h1>
        )}
        {error && (
          <h1>Error: {JSON.stringify(error.message)}</h1>
        )}
        {data && (
          <>
            <div className={styles.featured__card}>
              <h3 className={styles.featured__label}><span>Featured Video</span></h3>
              <h2 className={styles.featured__title}>{data?.video?.title}</h2>
              <p className={styles.featured__excerpt} dangerouslySetInnerHTML={{ __html: data?.video?.excerpt }} />
              <span className={styles.featured__duration}>{data?.video?.videoinformation?.duration}</span>
            </div>
            <div className={styles.featured__video}>
              <div className={styles.responsive__embed}>
                {!videoIsPlaying && <PlayButton className={styles.play__button} onClick={handlePlay}/>}
                <video playsInline controls={videoIsPlaying} ref={videoRef} onPause={handlePause}>
                  <source 
                    src={data?.video?.videoinformation?.videoFile?.mediaItemUrl} 
                    type={data?.video?.videoinformation?.videoSource?.mimeType} />
                </video>
              </div>
            </div>
          </>
        )}
      </Container>
    </div>
  );
}

BlockFeaturedVideo.propTypes = {
  postID: PropTypes.number.isRequired,
  colorScheme: PropTypes.string,
};

BlockFeaturedVideo.defaultProps = {
  colorScheme: 'white',
};