import { gql } from '@apollo/client';
import postCardFields from '@/lib/wp/_partials/postCardFields';

const queryPostsByArticleCategory = gql`
  query GET_POSTS_BY_ARTICLE_CATEGORY(
    $first: Int
    $orderBy: PostObjectsConnectionOrderbyEnum = DATE
    $order: OrderEnum = DESC
    $id: ID!
    $idType: ArticlecategoryIdType = SLUG
    $imageSize: MediaItemSizeEnum = LARGE
  ) {
    articlecategory(id: $id, idType: $idType) {
      contentNodes(
        first: $first
        where: {
          contentTypes: [POST]
          orderby: { field: $orderBy, order: $order }
          dateQuery: { after: { day: 1, month: 1, year: 2016 } }
        }
      ) {
        edges {
          node {
            contentType {
              node {
                graphqlSingleName
              }
            }
            ... on Post {
              ${postCardFields}
            }
          }
        }
      }
    }
  }
`;

export default queryPostsByArticleCategory;
