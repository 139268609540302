import PropTypes from 'prop-types';
import {useQuery} from "@apollo/client";
import queryPartners from "@/lib/wp/partners/queryPartners";
import styles from './BlockPartnerList.module.scss';
import Container from "@/components/Container";
import BlockPartnerItem from "@/components/BlockPartnerList/BlockPartnerItem";

/**
 * @param  {object}  props                    component attributes
 * @param  {Array}   props.numberOfPartners   number of partners to display
 * @return {JSX.Element}                      PartnerList element
 */
export default function BlockPartnerList({ numberOfPartners }) {
  const query = queryPartners;
  const { loading, error, data } = useQuery(query, { variables: { first: numberOfPartners } });
  if (loading) {
    return (
      <div>
        Loading data for partners...
      </div>
    );
  }

  if (error) {
    return <div>Error: {JSON.stringify(error.message)}</div>;
  }

  return (
    <div className={styles.partners__outer_wrapper}>
      <Container className={styles.partners__inner_wrapper}>
        <div className={styles.partners__title}>
          <h2>Our Partners</h2>
          <a href={`/partners`}>See All</a>
        </div>
        <div className={styles.partners__content}>
          {data?.partners?.edges?.map((edge) => {
            return (
              <BlockPartnerItem postData={edge.node}/>
            )
          })}
        </div>
      </Container>
    </div>
  )
}

BlockPartnerList.propTypes = {
  numberOfPartners: PropTypes.number.isRequired,
};
