import PropTypes from 'prop-types';
import styles from './BlockEmbed.module.scss';

/**
 * Renders an embed iframe block
 *
 * @param  {object}  props         component props
 * @param  {string}  props.caption caption for video
 * @param  {string}  props.url     src for video iframe
 * @return {Element}               iframe container element
 */

export default function BlockEmbed({ caption, url }) {
  return (
    <section>
      <div className={styles.container}>
        <iframe
          title={caption}
          src={url}
          className={styles.video}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture; playsinline"
          allowFullScreen
        />
      </div>
      <p className={styles.caption}>{caption}</p>
    </section>
  );
}

BlockEmbed.propTypes = {
  caption: PropTypes.string,
  url: PropTypes.string.isRequired,
};

BlockEmbed.defaultProps = {
  caption: '',
};
