/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { ArticleJsonLd, NextSeo } from 'next-seo';
import Meta from '@/components/Meta';
import Header from '@/components/Header';
import Footer from '@/components/Footer';

/**
 * Layout component for wrapping all content in
 *
 * @param  {any}     children       child component to render
 * @param  {boolean} hasJsonLd      include JSON-LD in head
 * @param  {object}  seo            page seo data
 * @param  {boolean} hasHubSpot     include HubSpot code after footer
 * @param  {boolean} hasDarkHeader  use dark header variation
 * @return {Element}                Layout component wrapper
 */
export default function Layout({ children, hasJsonLd, seo, hasHubSpot, hasDarkHeader }) {
  return (
    <>
      <NextSeo
        title={seo?.title}
        description={seo?.metaDesc}
        canonical={seo?.canonical}
        nofollow={seo?.metaRobotsNofollow !== 'follow'}
        noindex={seo?.metaRobotsNoindex !== 'index'}
        openGraph={{
          type: 'website',
          url: seo?.breadcrumbs?.[1],
          title: seo?.title,
          images: [{ url: seo?.opengraphImage?.sourceUrl }],
          description: seo?.metaDesc,
          site_name: 'Health Data Management',
        }}
      />
      {hasJsonLd && (
        <ArticleJsonLd
          url={seo?.canonical}
          title={seo?.title}
          images={[seo?.opengraphImage?.sourceUrl]}
          datePublished={seo?.opengraphPublishedTime}
          dateModified={seo?.opengraphModifiedTime}
          authorName={seo?.opengraphAuthor}
          description={seo?.metaDesc}
        />
      )}
      <Meta />
      <Header isDark={hasDarkHeader} />
      <main>{children}</main>
      <Footer />
      {hasHubSpot && (
        <>
          {/* Start of HubSpot Embed Code */}
          <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/19543514.js" />
          {/* End of HubSpot Embed Code */}
        </>
      )}
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.any.isRequired,
  hasJsonLd: PropTypes.bool,
  seo: PropTypes.shape({
    breadcrumbs: PropTypes.array,
    canonical: PropTypes.string,
    metaDesc: PropTypes.string,
    metaRobotsNoindex: PropTypes.string,
    metaRobotsNofollow: PropTypes.string,
    opengraphAuthor: PropTypes.string,
    opengraphModifiedTime: PropTypes.string,
    opengraphPublishedTime: PropTypes.string,
    opengraphImage: PropTypes.shape({
      altText: PropTypes.string,
      sourceUrl: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  hasHubSpot: PropTypes.bool,
  hasDarkHeader: PropTypes.bool,
};

Layout.defaultProps = {
  hasJsonLd: false,
  seo: {},
  hasHubSpot: false,
  hasDarkHeader: false,
};
