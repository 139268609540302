import PropTypes from 'prop-types';
import displayBlock from '@/functions/wp/blocks/displayBlock';

/**
 * Render a map of blocks
 *
 * @param  {object}  props        component attributes
 * @param  {Array}   props.blocks array of gutenberg blocks
 * @return {Element}              collection of blocks
 */
export default function Blocks({ blocks }) {
  // console.log('blocks', blocks)
  return <>{blocks?.length >= 1 && blocks?.map((block, i) => displayBlock(block, i))}</>;
}

Blocks.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  blocks: PropTypes.array,
};

Blocks.defaultProps = {
  blocks: [],
};
