import PropTypes from 'prop-types';
import cn from 'classnames';
import { useQuery } from '@apollo/client';
import getEventById from '@/lib/wp/content/getEventById';
import styles from './BlockFeaturedEvent.module.scss';
import Container from '../Container';

export default function BlockFeaturedEvent({ postID, colorScheme }) {
  const query = getEventById;
  const { loading, error, data } = useQuery(query, { variables: { id: postID } });

  const colorMap = {
    white: '',
    wheat: styles['article__wrapper--wheat'],
    gray: styles['article__wrapper--gray'],
    green: styles['article__wrapper--green'],
  };

  const backgroundClass = colorScheme === 'white' ? '' : styles['article__wrapper--background'];

  return (
    <div className={cn(styles.article__wrapper, backgroundClass, colorMap[colorScheme])}>
      <Container>
        {loading && (
          <h1>Loading...</h1>
        )}
        {error && (
          <h1>Error: {JSON.stringify(error.message)}</h1>
        )}
        {data && (
          <>
            <div className={styles.featured__card}>
              <div className={styles.featured__header}>
                <h3 className={styles.featured__label}><span>Events</span></h3>
                <div className={styles.featured__date}>
                  <span className={styles.featured__duration}>{data?.event?.events?.startDate}</span>
                  {data?.event?.events?.startTime && (
                    <span className={styles.featured__duration}>{data?.event?.events?.startTime}</span>
                  )}
                </div>
              </div>
              {data?.event?.events?.location && (
                <span className={styles.featured__location}>{data?.event?.events?.location}</span>
              )}
              <h2 className={styles.featured__title}>{data?.event?.title}</h2>
              <p className={styles.featured__excerpt} dangerouslySetInnerHTML={{ __html: data?.event?.events?.description }} />
            </div>
            <div className={styles.featured__image}>
              <div className={styles.image__wrapper}>
                <img
                  style={{
                    objectPosition: `${data?.post?.focalPointX * 100}% ${data?.post?.focalPointY * 100}%`,
                  }}
                  src={data?.event?.featuredImage?.node?.sourceUrl}
                  alt={data?.event?.featuredImage?.node?.altText}
                />
              </div>
            </div>
          </>
        )}
      </Container>
    </div>
  );
}

BlockFeaturedEvent.propTypes = {
  postID: PropTypes.number.isRequired,
  colorScheme: PropTypes.string,
};

BlockFeaturedEvent.defaultProps = {
  colorScheme: 'white',
};