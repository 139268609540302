import PropTypes from 'prop-types';
import style from './BlockList.module.scss';

/**
 * Renders a list block
 *
 * @param  {object}  props         component props
 * @param  {boolean} props.ordered list is ordered or not
 * @param  {string}  props.values  values for the list in html format
 * @return {Element}               list element
 */
export default function BlockList({ ordered, values }) {
  const formatList = () => ({ __html: values });
  if (ordered) {
    return <ol className={style.ol} dangerouslySetInnerHTML={formatList()} />;
  }
  return <ul className={style.ul} dangerouslySetInnerHTML={formatList()} />;
}

BlockList.propTypes = {
  ordered: PropTypes.bool.isRequired,
  values: PropTypes.string.isRequired,
};
