import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import BlockArticleCard from '@/components/BlockArticleCard';
import Blocks from '@/components/Blocks';
import styles from '../BlockTopicCluster.module.scss';

/**
 * Render Small variation of Topic Cluster
 *
 * @param  {object}  props               component attributes
 * @param  {object}  props.data          wp data
 * @param  {number}  props.columns       number of columns to display
 * @param  {string}  props.colorScheme   color scheme for display
 * @param  {bool}    props.isManual
 * @param  {string}  accentColorOverride optional color override for drop shadow
 * @param            props.innerBlocks
 * @return {Element}                     Small topic cluster
 */
export default function Small({ data, columns, colorScheme, isManual = false, innerBlocks, tax, accentColorOverride }) {
  const [isTablet, setIsTablet] = useState(false);
  const [cardBlocks, setCardBlocks] = useState(null);

  useEffect(() => {
    if (isManual && innerBlocks) {
      const newBlocks = innerBlocks
        .filter((item) => !!item.attributes.postID || !!item.attributes.postData)
        .map((block, index) => ({
          ...block,
          cardType: index === 0 ? 'feature-small' : 'condensed',
          columnSpan: columns,
          attributes:{ ...block.attributes,
            accentColorOverride: accentColorOverride ? accentColorOverride : ""},
          whiteBackground: index === 0 ? colorScheme === 'green' || colorScheme === 'wheat' : false,
          lightText: index === 0 ? false : colorScheme === 'green',
        }));
      setCardBlocks(newBlocks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**
     * Sets card type based on screen size
     *
     * @param {event} e matchMedia change event
     */
    function setCard(e) {
      setIsTablet(e.matches);
    }

    const mql = window?.matchMedia('(min-width: 913px) and (max-width: 1237px)');
    mql.addEventListener('change', setCard);
    setCard(mql);
  }, []);

  if (isManual && !!cardBlocks) {
    return (
      <>
        <div className={styles.card__feature_small}>
          <Blocks blocks={[cardBlocks[0]]} />
          {isTablet && (
            <div className={styles.card__feature_small_condensed}>
              <Blocks blocks={[cardBlocks[1]]} />
              <Blocks blocks={[cardBlocks[2]]} />
            </div>
          )}
        </div>
        {isTablet ? (
          <div className={styles.card__list_small}>
            <Blocks blocks={cardBlocks.slice(3)} />
          </div>
        ) : (
          <div className={styles.card__list_small}>
            <Blocks blocks={cardBlocks.slice(1)} />
          </div>
        )}
      </>
    );
  }

  if (!isManual) {
    return (
      <>
        <div className={styles.card__feature_small}>
          <BlockArticleCard
            postID={data?.[tax]?.contentNodes?.edges?.[0]?.node?.databaseId}
            postType={data?.[tax]?.contentNodes?.edges?.[0]?.node?.contentType?.node?.graphqlSingleName}
            postData={data?.[tax]?.contentNodes?.edges?.[0]?.node ?? {}}
            cardType="feature-small"
            columnSpan={columns}
            whiteBackground={colorScheme === 'green' || colorScheme === 'wheat'}
            accentColorOverride={accentColorOverride}
          />
          {isTablet && (
            <div className={styles.card__feature_small_condensed}>
              <BlockArticleCard
                postID={data?.[tax]?.contentNodes?.edges?.[1]?.node?.databaseId}
                postType={data?.[tax]?.contentNodes?.edges?.[1]?.node?.contentType?.node?.graphqlSingleName}
                postData={data?.[tax]?.contentNodes?.edges?.[1]?.node ?? {}}
                cardType="condensed"
                columnSpan={columns}
              />
              <BlockArticleCard
                postID={data?.[tax]?.contentNodes?.edges?.[2]?.node?.databaseId}
                postType={data?.[tax]?.contentNodes?.edges?.[2]?.node?.contentType?.node?.graphqlSingleName}
                postData={data?.[tax]?.contentNodes?.edges?.[2]?.node ?? {}}
                cardType="condensed"
                columnSpan={columns}
              />
            </div>
          )}
        </div>
        {isTablet ? (
          <div className={styles.card__list_small}>
            {data?.[tax]?.contentNodes?.edges?.slice(3)?.map((edge) => {
              const postID = edge?.node?.databaseId;
              const postType = edge?.node?.contentType?.node?.graphqlSingleName;
              return (
                <BlockArticleCard
                  key={postID}
                  postID={postID}
                  postType={postType}
                  cardType="condensed"
                  columnSpan={columns}
                  postData={edge?.node ?? {}}
                />
              );
            })}
          </div>
        ) : (
          <div className={styles.card__list_small}>
            {data?.[tax]?.contentNodes?.edges?.slice(1)?.map((edge) => {
              const postID = edge?.node?.databaseId;
              const postType = edge?.node?.contentType?.node?.graphqlSingleName;
              return (
                <BlockArticleCard
                  key={postID}
                  postID={postID}
                  postType={postType}
                  cardType="condensed"
                  columnSpan={columns}
                  lightText={colorScheme === 'green'}
                  postData={edge?.node ?? {}}
                />
              );
            })}
          </div>
        )}
      </>
    );
  }

  return null;
}

Small.propTypes = {
  data: PropTypes.shape({
    tag: PropTypes.shape({
      contentNodes: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              databaseId: PropTypes.number,
              contentType: PropTypes.shape({
                node: PropTypes.shape({
                  graphqlSingleName: PropTypes.string,
                }),
              }),
            }),
          })
        ),
      }),
    }),
  }).isRequired,
  columns: PropTypes.number,
  colorScheme: PropTypes.string,
  accentColorOverride: PropTypes.string,
};

Small.defaultProps = {
  columns: 7,
  colorScheme: 'white',
  accentColorOverride: ''
};
