import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import queryByTag from '@/lib/wp/content/getPostByTag';
import queryByDomain from '@/lib/wp/content/getPostByDomain';
import queryByCompanyType from '@/lib/wp/content/getPostByCompanyType';
import queryByJobFunction from '@/lib/wp/content/getPostByJobFunction';
import queryByJobLevel from '@/lib/wp/content/getPostByJobLevel';
import queryByTopic from '@/lib/wp/content/getPostByTopic';
import queryByEventCategory from '@/lib/wp/content/getPostByEventCategory';
import queryByArticleCategory from '@/lib/wp/content/getPostByArticleCategory';
import queryVideosByCategory from '@/lib/wp/content/getPostByVideoCategory';
import Small from './variations/Small';
import Medium from './variations/Medium';
import Full from './variations/Full';
import styles from './BlockTopicCluster.module.scss';

/**
 * Renders a horizontal display of article cards based on taxonomy
 *
 * @param  {object}  props                      component props
 * @param  {number}  props.columns              number of items to display
 * @param  {object}  props.topic                contains the taxonomy name and the term name/slug
 * @param  {string}  props.featureType          type of feature display
 * @param  {string}  props.colorScheme          color scheme for display
 * @param  {string}  props.accentColorOverride  optional color override for drop shadow
 * @return {Element}                            TaxonomyList element
 */
export default function TaxonomyList({ topic, columns, featureType, colorScheme, tax, accentColorOverride }) {
  const queryMap = {
    tags: queryByTag,
    hdm_tax_domain: queryByDomain,
    hdm_tax_company_type: queryByCompanyType,
    hdm_tax_job_function: queryByJobFunction,
    hdm_tax_job_level: queryByJobLevel,
    hdm_tax_topic: queryByTopic,
    hdm_cat_article: queryByArticleCategory,
    hdm_cat_event: queryByEventCategory,
    hdm_cat_video: queryVideosByCategory
  };
  const query = queryMap[topic?.taxonomy];
  const variables = { id: topic?.term?.slug ?? null, first: columns };
  const { data, loading, error } = useQuery(query, { variables });

  const messageColor = colorScheme === 'green' ? styles['message--wheat'] : styles.message;

  if (loading) {
    return (
      <div className={messageColor}>
        Loading data for {topic?.taxonomy} #{topic?.term?.slug}...
      </div>
    );
  }

  if (error) {
    return (
      <div className={messageColor}>
        Error: {JSON.stringify(error.message)}
      </div>
    );
  }

  switch (featureType) {
    case 'small':
      return <Small data={data} columns={columns} colorScheme={colorScheme} tax={tax} accentColorOverride={accentColorOverride} />;
    case 'medium':
      return <Medium data={data} columns={columns} colorScheme={colorScheme} tax={tax} accentColorOverride={accentColorOverride} />;
    case 'full':
      return <Full data={data} columns={columns} colorScheme={colorScheme} tax={tax} accentColorOverride={accentColorOverride} />;
    default:
      return <Medium data={data} columns={columns} colorScheme={colorScheme} tax={tax} accentColorOverride={accentColorOverride} />;
  }
}

TaxonomyList.propTypes = {
  columns: PropTypes.number.isRequired,
  topic: PropTypes.shape({
    taxonomy: PropTypes.string,
    term: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  }).isRequired,
  featureType: PropTypes.string.isRequired,
  colorScheme: PropTypes.string,
  accentColorOverride: PropTypes.string,
};

TaxonomyList.defaultProps = {
  colorScheme: 'white',
  accentColorOverride: '',
};
