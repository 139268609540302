import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './BlockAudio.module.scss';

const useAudio = (url) => {
  const [audio] = useState(typeof Audio !== 'undefined' && new Audio(url));
  const [playing, setPlaying] = useState(false);
  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    if (playing) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [playing, audio]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  return [playing, toggle];
};

const Player = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <button
      type="button"
      onClick={toggle}
      className={`${styles.audio__btn} ${playing ? styles.audio__btn__pause : styles.audio__btn__play}`}
    />
  );
};

/**
 * Renders an audio block
 *
 * @param  {object}  props         component props
 * @param  {string}  props.src     audio source
 * @param  {string}  props.caption caption for audio
 * @return {Element}               audio element
 */
export default function BlockAudio({ src, caption }) {
  return (
    <figure>
      <Player url={src} />
      {caption ? <figcaption>{caption}</figcaption> : null}
    </figure>
  );
}

Player.defaultProps = {
  url: '',
};

Player.propTypes = {
  url: PropTypes.string,
};

BlockAudio.defaultProps = {
  caption: '',
};

BlockAudio.propTypes = {
  src: PropTypes.string.isRequired,
  caption: PropTypes.string,
};
