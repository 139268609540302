import PropTypes from 'prop-types';

export default function PlayButton({ className, onClick }) {
  return (
    <svg 
      className={className}
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path d="M16 10v28l22-14z" fill="#1b4d48" />
    </svg>
  )
}

PlayButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

PlayButton.defaultProps = {
  className: '',
  onClick: () => false
};