import { gql } from '@apollo/client';
import postCardFields from '@/lib/wp/_partials/postCardFields';
import eventFields from '@/lib/wp/_partials/eventFields';


const getEventById = gql`
  query GET_EVENT_BY_ID($id: ID!, $idType: EventIdType = DATABASE_ID, $imageSize: MediaItemSizeEnum = LARGE) {
    event(id: $id, idType: $idType) {
      ${postCardFields}
      ${eventFields}
    }
  }
`;

export default getEventById;
