import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import BlockArticleCard from '@/components/BlockArticleCard';
import queryByTag from '@/lib/wp/content/getPostByTag';
import queryByDomain from '@/lib/wp/content/getPostByDomain';
import queryByCompanyType from '@/lib/wp/content/getPostByCompanyType';
import queryByJobFunction from '@/lib/wp/content/getPostByJobFunction';
import queryByJobLevel from '@/lib/wp/content/getPostByJobLevel';
import queryByTopic from '@/lib/wp/content/getPostByTopic';
import queryByEventCategory from '@/lib/wp/content/getPostByEventCategory';
import queryByArticleCategory from '@/lib/wp/content/getPostByArticleCategory';
import queryByVideoCategory from '@/lib/wp/content/getPostByVideoCategory';

/**
 * Renders a horizontal display of article cards based on taxonomy
 *
 * @param  {object}  props          component props
 * @param  {number}  props.columns  number of items to display
 * @param  {object}  props.topic    contains the taxonomy name and the term name/slug
 * @param  {string}  props.cardType variation to display
 * @return {Element}                TaxonomyList element
 */
export default function TaxonomyList({ topic, columns, cardType }) {
  const queryMap = {
    tags: queryByTag,
    hdm_tax_domain: queryByDomain,
    hdm_tax_company_type: queryByCompanyType,
    hdm_tax_job_function: queryByJobFunction,
    hdm_tax_job_level: queryByJobLevel,
    hdm_tax_topic: queryByTopic,
    hdm_cat_article: queryByArticleCategory,
    hdm_cat_event: queryByEventCategory,
    hdm_cat_video: queryByVideoCategory,
  };

  const taxonomyMap = {
    tags: 'tag',
    hdm_tax_domain: 'domain',
    hdm_tax_company_type: 'companytype',
    hdm_tax_job_function: 'jobfunction',
    hdm_tax_job_level: 'joblevel',
    hdm_tax_topic: 'topic',
    hdm_cat_article: 'articlecategory',
    hdm_cat_event: 'eventcategory',
    hdm_cat_video: 'videocategory',
  };

  const query = queryMap[topic?.taxonomy];
  const variables = { id: topic?.term?.slug ?? null, first: columns };
  const { data, loading, error } = useQuery(query, { variables });

  if (loading) {
    return (
      <div>
        Loading data for {topic?.taxonomy} #{topic?.term?.slug}...
      </div>
    );
  }

  if (error) {
    return <div>Error: {JSON.stringify(error.message)}</div>;
  }

  return (
    <>
      {data?.[taxonomyMap[topic?.taxonomy]]?.contentNodes?.edges?.map((edge) => {
        const postID = edge?.node?.databaseId;
        const postType = edge?.node?.contentType?.node?.graphqlSingleName;
        return (
          <BlockArticleCard
            postID={postID}
            postType={postType}
            cardType={cardType}
            columnSpan={columns}
            postData={edge?.node}
          />
        );
      })}
    </>
  );
}

TaxonomyList.propTypes = {
  columns: PropTypes.number.isRequired,
  topic: PropTypes.shape({
    taxonomy: PropTypes.string,
    term: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  }).isRequired,
  cardType: PropTypes.string.isRequired,
};
