import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import BlockArticleCard from '@/components/BlockArticleCard';
import queryByType from '@/lib/wp/content/getPostByType';

/**
 * Renders a horizontal display of article cards based on taxonomy
 *
 * @param  {object}  props          component props
 * @param  {number}  props.columns  number of items to display
 * @param  {string}  props.cardType variation to display
 * @return {Element}                TaxonomyList element
 */
export default function ShowRecent({ columns, cardType }) {
  const query = queryByType;
  const variables = { first: columns };
  const { data, loading, error } = useQuery(query, { variables });

  if (loading) {
    return (
      <div>
        Loading data for recent posts...
      </div>
    );
  }

  if (error) {
    return <div>Error: {JSON.stringify(error.message)}</div>;
  }

  return (
    <>
      {data?.contentNodes?.edges?.map((edge) => {
        const postID = edge?.node?.databaseId;
        const postType = edge?.node?.contentType?.node?.graphqlSingleName;
        return (
          <BlockArticleCard
            postID={postID}
            postType={postType}
            cardType={cardType}
            columnSpan={columns}
            postData={edge?.node}
          />
        );
      })}
    </>
  );
}

ShowRecent.propTypes = {
  columns: PropTypes.number.isRequired,
  cardType: PropTypes.string.isRequired,
};
