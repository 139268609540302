/* eslint-disable react/jsx-props-no-spreading */
import BlockParagraph from '@/components/BlockParagraph';
import BlockImage from '@/components/BlockImage';
import BlockSpacer from '@/components/BlockSpacer';
import BlockList from '@/components/BlockList';
import BlockQuote from '@/components/BlockQuote';
import BlockVideo from '@/components/BlockVideo';
import BlockAudio from '@/components/BlockAudio';
import BlockHeading from '@/components/BlockHeading';
import BlockTable from '@/components/BlockTable';
import BlockFreeform from '@/components/BlockFreeform';
import BlockArticleCard from '@/components/BlockArticleCard';
import BlockArticleColumns from '@/components/BlockArticleColumns';
import BlockTopicCluster from '@/components/BlockTopicCluster';
import BlockFilteredList from '@/components/BlockFilteredList';
import BlockLanding from '@/components/BlockLanding';
import BlockConversations from '@/components/BlockConversations';
import BlockSeparator from '@/components/BlockSeparator';
import BlockPartnerList from '@/components/BlockPartnerList';
import BlockFeaturedVideo from '@/components/BlockFeaturedVideo';
import BlockFeaturedEvent from '@/components/BlockFeaturedEvent';
import BlockEmbed from '@/components/BlockEmbed';
import BlockButtons from '@/components/BlockButtons';
import BlockButton from '@/components/BlockButton';

/**
 * Switch statement for outputting gutenberg block
 *
 * @param  {object}  block gutenberg block props
 * @param  {number}  id    number for key
 * @return {Element}       block element
 */
export default function displayBlock(block, id) {
  const { innerBlocks, attributes, name } = block;

  switch (name) {
    case 'core/paragraph':
      return <BlockParagraph key={id} {...attributes} />;

    case 'core/spacer':
      return <BlockSpacer key={id} {...attributes} />;

    case 'core/list':
      return <BlockList key={id} {...attributes} />;

    case 'core/quote':
      return <BlockQuote key={id} {...attributes} />;

    case 'core/audio':
      return <BlockAudio key={id} {...attributes} />;

    case 'core/heading':
      return <BlockHeading key={id} {...attributes} />;

    case 'core/image':
      return <BlockImage key={id} {...attributes} />;

    case 'core/table':
      return <BlockTable key={id} {...attributes} />;

    case 'core/video':
      return <BlockVideo key={id} {...attributes} />;

    case 'core/embed':
      return <BlockEmbed key={id} {...attributes} />;

    case 'core/freeform':
      return <BlockFreeform key={id} {...attributes} />;

    case 'core/separator':
      return <BlockSeparator key={id} />;

    case 'core/buttons':
      return <BlockButtons key={id} {...attributes} innerBlocks={innerBlocks} />

    case 'core/button':
      return <BlockButton key={id} {...attributes} />

    case 'hdm/article-card':
      return (
        <BlockArticleCard
          key={attributes.postID}
          {...attributes}
          {...block}
          cardType={block?.cardType ?? null}
          columnSpan={block?.columnSpan ?? null}
        />
      );

    case 'hdm/article-columns':
      return <BlockArticleColumns key={id} {...attributes} innerBlocks={innerBlocks} />;

    case 'hdm/topics-cluster':
      return <BlockTopicCluster key={id} {...attributes} innerBlocks={innerBlocks} />;

    case 'hdm/filtered-list':
      return <BlockFilteredList key={id} {...attributes} initialPosts={block?.initialPosts || []} />;

    case 'hdm/landing':
      return <BlockLanding key={id} {...attributes} innerBlocks={innerBlocks} />;

    case 'hdm/conversations':
      return <BlockConversations key={id} {...attributes} />;

    case 'hdm/logo-links':
      return <BlockPartnerList key={id} {...attributes} />;

    case 'hdm/featured-video':
      return <BlockFeaturedVideo key={id} {...attributes} initialContent={block?.initialContent}/>;

    case 'hdm/featured-event':
      return <BlockFeaturedEvent key={id} {...attributes} />;

    default:
      return (
        <pre style={{ whiteSpace: 'pre-wrap', background: 'black', color: 'white', padding: '20px' }}>
          <code>{JSON.stringify(attributes, null, 4)}</code>
        </pre>
      );
  }
}
