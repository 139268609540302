import PropTypes from 'prop-types';
import LatestList from './LatestList';
import PostTypeList from './PostTypeList';
import TaxonomyList from './TaxonomyList';

/**
 * @param  {object}  props        component attributes
 * @param  {object}  props.filter selected filter object
 * @return {Element}              NodeList component
 */
export default function NodeList({ filter, tax, numberOfPosts, initialPosts }) {
  if (!filter) {
    return null;
  }

  switch (filter.type) {
    case 'latest':
      return <LatestList numberOfPosts={numberOfPosts} initialPosts={initialPosts} />;
    case 'types':
      return <PostTypeList numberOfPosts={numberOfPosts} filter={filter.value} />;
    default:
      return <TaxonomyList numberOfPosts={numberOfPosts} taxonomy={filter.type} filter={filter.value} tax={tax} />;
  }
}

NodeList.propTypes = {
  filter: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.oneOf([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
      }),
    ]),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tax: PropTypes.object,
  numberOfPosts: PropTypes.number.isRequired,
  initialPosts: PropTypes.array
};

NodeList.defaultProps = {
  tax: {},
  initialPosts: []
};
