import PropTypes from 'prop-types';

/**
 * Renders a video block
 *
 * @param  {object}  props          component props
 * @param  {string}  props.anchor   link for video
 * @param  {string}  props.caption  caption for video
 * @param  {boolean} props.controls controls for video
 * @param  {string}  props.src      src for video
 * @return {Element}                video element
 */

export default function BlockVideo({ anchor, caption, controls, src }) {
  return (
    <figure>
      <video width="100%" controls>
        <source src={src} />
      </video>
      {caption && <figcaption>{caption}</figcaption>}
    </figure>
  );
}

BlockVideo.propTypes = {
  anchor: PropTypes.string,
  caption: PropTypes.string,
  controls: PropTypes.bool,
  src: PropTypes.string.isRequired,
};
