import PropTypes from 'prop-types';
import styles from './BlockPartnerList.module.scss';
import {useQuery} from "@apollo/client";
import queryPostById from "@/lib/wp/content/getPost";

/**
 * @param  {object}  props                      component attributes
 * @param  {object}  props.postData             partner data from wp
 * @return {JSX.Element}                        PartnerList element
 */
export default function BlockPartnerItem({postData}) {
  const query = queryPostById;
  const partnerInfo = postData?.partnerInformation;

  const { loading, error, data } = useQuery(query, { variables: { id: partnerInfo?.partnerFeatured?.databaseId } });

  return (
    <div className={styles.partner__wrapper}>
      <div className={styles.partner__image}>
        <img src={partnerInfo?.partnerLogo?.sourceUrl} alt={postData?.title}/>
      </div>
      <div className={styles.partner__text}>
        <a className={styles.partner__link} href={postData?.uri}>
          {postData?.title}
        </a>
        {!loading && !error && data &&
          <>
            <h3 className={styles.partner__headline}>
              <a href={data?.post.uri}>
                {data?.post.title}
              </a>
            </h3>
            <div className={styles.partner__excerpt} dangerouslySetInnerHTML={{__html: data?.post?.excerpt}} />
          </>
        }
      </div>
    </div>
  )
}

BlockPartnerItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  postData: PropTypes.any,
};

BlockPartnerItem.defaultProps = {
  postData: {},
};
