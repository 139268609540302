import PropTypes from 'prop-types';

/**
 * Renders a spacer block
 *
 * @param  {object}  props        component props
 * @param  {string}  props.height height of spacer
 * @return {Element}              spacer element
 */

export default function BlockSpacer({ height }) {
  return <div style={{ height }} />;
}

BlockSpacer.propTypes = {
  height: PropTypes.number.isRequired,
};
