import PropTypes from 'prop-types';
import style from './BlockImage.module.scss';

/**
 * Renders an image block
 *
 * @param  {object}  props            component props
 * @param  {string}  props.url        image url
 * @param  {string}  props.alt        alt for image
 * @param  {string}  props.caption    caption for image
 * @param  {string}  props.href       href for image link
 * @param  {string}  props.linkClass  class for image link
 * @param  {string}  props.linkTarget target for image link
 * @param  {string}  props.rel        rel for image link
 * @return {Element}                  image element
 */
export default function BlockImage({ url, alt, caption, href, linkClass, linkTarget, rel }) {
  return (
    <figure className={style.figure}>
      {href ? (
        <a href={href} className={linkClass} target={linkTarget} rel={rel}>
          <img src={url} alt={alt} />
        </a>
      ) : (
        <img src={url} alt={alt} />
      )}
      {caption && <figcaption dangerouslySetInnerHTML={{ __html: caption }} />}
    </figure>
  );
}

BlockImage.defaultProps = {
  alt: '',
  caption: null,
  href: null,
  linkClass: null,
  linkTarget: null,
  rel: null
};

BlockImage.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
  caption: PropTypes.string,
  href: PropTypes.string,
  linkClass: PropTypes.string,
  linkTarget: PropTypes.string,
  rel: PropTypes.string
};
