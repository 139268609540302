import PropTypes from 'prop-types';
import style from './BlockQuote.module.scss';

/**
 * Renders an image block
 *
 * @param  {object}  props          component props
 * @param  {string}  props.value    quote content
 * @param  {string}  props.citation citation for quote
 * @param  {string}  props.anchor   anchor link for quote
 * @return {Element}                image element
 */
export default function BlockQuote({ value, citation, anchor }) {
  /**
   */
  function formatQuote() {
    return { __html: value };
  }
  return (
    <div className={style.blockQuote}>
      <blockquote dangerouslySetInnerHTML={formatQuote()} />
      <p>-{citation}</p>
    </div>
  );
}

BlockQuote.propTypes = {
  value: PropTypes.string.isRequired,
  citation: PropTypes.string,
  anchor: PropTypes.string,
};
