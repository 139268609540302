import Head from 'next/head';

/**
 * Render extra meta tags in the document head.
 *
 * @return {Element} The Meta component.
 */
export default function Meta() {
  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <link rel="icon" href="/images/favicon-32x32.png" type="image/png" />
      <link rel="shortcut icon" href="/images/favicon.ico" />
      
      {/* TODO: Include Favicon/Theme Tags */}
    </Head>
  );
}
