import PropTypes from 'prop-types';
import styles from './BlockButton.module.scss';

/**
 * Renders a button
 *
 * @param  {object}  props              Component props
 * @param  {string}  props.anchor       Optional anchor/id
 * @param  {string}  props.linkTarget   The target for the link
 * @param  {string}  props.rel          The rel attribute for the link
 * @param  {string}  props.text         The link label
 * @param  {string}  props.url          The link for the button
 * @return {Element}                    Button component
 */
export default function BlockButton({
  anchor,
  linkTarget,
  rel,
  text,
  url
}) {
  return (
    <a id={anchor} href={url} target={linkTarget} rel={rel} className={styles.button__primary}>{text}</a>
  );
}

BlockButton.defaultProps = {
  anchor: null,
  linkTarget: null,
  rel: null,
  url: null
};

BlockButton.propTypes = {
  anchor: PropTypes.string,
  linkTarget: PropTypes.string,
  rel: PropTypes.string,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};