import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import BlockArticleCard from '@/components/BlockArticleCard';
import queryByType from '@/lib/wp/content/getPostByType';

/**
 * PostTypeList element for a filtered list component
 *
 * @param  {object}  props        component attributes
 * @param  {string}  props.filter post type used for filter
 * @return {Element}              PostTypeList element
 */
export default function PostTypeList({ filter, numberOfPosts }) {
  const query = queryByType;
  const variables = { first: numberOfPosts, postType: filter?.slug === 'posts' ? 'POST' : filter?.slug?.toUpperCase() };
  const { data, loading, error } = useQuery(query, { variables });

  if (loading) {
    return <div>Loading data for {filter?.name} nodes...</div>;
  }

  if (error) {
    return <div>Error: {JSON.stringify(error.message)}</div>;
  }

  return (
    <>
      {data?.contentNodes?.edges?.map((edge) => {
        const postID = edge?.node?.databaseId;
        const postType = edge?.node?.contentType?.node?.graphqlSingleName;
        return (
          <BlockArticleCard
            key={postID}
            postID={postID}
            postType={postType}
            cardType="condensed"
            columnSpan={6}
          />
        );
      })}
    </>
  );
}

PostTypeList.propTypes = {
  filter: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }).isRequired,
  numberOfPosts: PropTypes.number.isRequired,
};
