import cn from 'classnames';
import PropTypes from 'prop-types';
import Blocks from '@/components/Blocks';
import Container from '@/components/Container';
import { useEffect, useState } from 'react';
import TaxonomyList from './TaxonomyList';
import ShowRecent from './ShowRecent';
import styles from './BlockArticleColumns.module.scss';

/**
 * Renders a horizontal display of article cards
 *
 * @param  {object}  props              component props
 * @param  {Array}   props.innerBlocks  array of innerblocks from gutenberg
 * @param  {number}  props.columns      number of items to display
 * @param  {boolean} props.manualSelect determines if innerBlocks should be used (TRUE) or not (FALSE)
 * @param  {string}  props.title        section title
 * @param  {object}  props.topic        contains the taxonomy name and the term name/slug
 * @param  {boolean} props.showRecent   show the most recent N posts
 * @return {Element}                    Article Columns element
 */
export default function BlockArticleColumns({ innerBlocks, columns, manualSelect, title, topic, showRecent }) {
  const [cardType, setCardType] = useState(null);
  const [cardBlocks, setCardBlocks] = useState(null);

  const taxonomyMap = {
    tags: 'tag',
    hdm_tax_domain: 'domain',
    hdm_tax_company_type: 'companytype',
    hdm_tax_job_function: 'jobfunction',
    hdm_tax_job_level: 'joblevel',
    hdm_tax_topic: 'topic',
    hdm_cat_article: 'articlecategory',
    hdm_cat_event: 'eventcategory',
  };

  useEffect(() => {
    /**
     * Sets card type based on screen size
     *
     * @param {event} e matchMedia change event
     */
    function setCard(e) {
      const mobileView = !e.matches;
      if (mobileView) {
        setCardType('inline');
      } else {
        setCardType('small-row');
      }
    }

    const mql = window?.matchMedia('(min-width: 913px)');
    mql.addEventListener('change', setCard);
    setCard(mql);
  }, []);

  useEffect(() => {
    if (innerBlocks) {
      const newBlocks = innerBlocks.filter((item) => 
        !!item.attributes.postID || !!item.attributes.postData
      ).map((block) => {
        // eslint-disable-next-line no-param-reassign
        block.cardType = cardType;
        // eslint-disable-next-line no-param-reassign
        block.columnSpan = columns;
        return block;
      });
      setCardBlocks(newBlocks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardType]);

  return (
    <Container className={cn(styles.article__cards, `columns-${columns}`)}>
      <div className={styles.article__cards_title}>
        {title && <h2>{title}</h2>}
        {/* {topic?.taxonomy && <a href={`/${taxonomyMap[topic?.taxonomy]}/${topic?.term?.slug}`}>See All</a>} */}
      </div>
      {manualSelect && cardBlocks && <Blocks blocks={cardBlocks} />}
      {!manualSelect && !showRecent && <TaxonomyList topic={topic} columns={columns} cardType={cardType} />}
      {!manualSelect && showRecent && <ShowRecent columns={columns} cardType={cardType} />}
    </Container>
  );
}

BlockArticleColumns.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  innerBlocks: PropTypes.array,
  columns: PropTypes.number,
  manualSelect: PropTypes.bool,
  title: PropTypes.string,
  topic: PropTypes.shape({
    taxonomy: PropTypes.string,
    term: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  }),
  showRecent: PropTypes.bool,
};

BlockArticleColumns.defaultProps = {
  innerBlocks: [],
  columns: 3,
  manualSelect: true,
  title: '',
  topic: {
    taxonomy: '',
    term: {
      name: '',
      slug: '',
    },
  },
  showRecent: false,
};
