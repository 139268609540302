/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import styles from './BlockFilteredList.module.scss';
import NodeList from './NodeList';
import Container from '../Container';
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import carouselStyle from "styles/jss/nextjs-material-kit-pro/pages/componentsSections/carouselStyle.js";


/**
 * @param  {object}  props         component attributes
 * @param  {Array}   props.filters array of filters to display
 * @return {Element}               FilterdList element
 */
export default function BlockFilteredList({ filters, numberOfPosts, initialPosts }) {
  const [selectedFilter, setSelectedFilter] = useState(null);

  const taxonomyMap = {
    tags: 'tag',
    hdm_tax_domain: 'domain',
    hdm_tax_company_type: 'companytype',
    hdm_tax_job_function: 'jobfunction',
    hdm_tax_job_level: 'joblevel',
    hdm_tax_topic: 'topic',
    hdm_cat_article: 'articlecategory',
    hdm_cat_event: 'eventcategory',
    hdm_cat_video: 'videocategory',
  };

  /**
   * Set the active filter after user interaction
   *
   * @param {object} filter filter object selected by user
   */
  function changeFilter(filter) {
    setSelectedFilter(filter);
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "flex", background: "rgb(23 23 23 / 6%)" , right: '-20px', height: '0px', alignItems: "center", boxShadow: "black 0 0 1px 0px", border: "none"}}
        onClick={onClick}
      />      
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "flex", background: "rgb(23 23 23 / 6%)", left: '-20px', height: '0px', alignItems: "center", boxShadow: "black 0 0 1px 0px", border: "none"}}
        onClick={onClick}
      />
    );
  }

  useEffect(() => {
    if (!selectedFilter) {
      setSelectedFilter(filters[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var moreLink = '';
  if (selectedFilter?.type !== 'latest') {
    if (selectedFilter?.type === 'types') {
      moreLink = `${selectedFilter?.value?.slug}`;
    } else {
      moreLink = `${taxonomyMap[selectedFilter?.type]}/${selectedFilter?.value?.slug}`;
    }
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    swipeToSlide: true,
    // slidesToShow: 2,
    // slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
    // autoplay: true
    // arrows: false
  };

  return (
    <Container className={styles.filtered_list}>
      {/* <ul  */}
      <Carousel {...settings} 
      className={styles.filters}
      >
        {filters.map(
          (filter, index) =>
            index <= numberOfPosts && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
              <div
                key={index}
                className={cn(styles.filter, { [styles.filter_active]: filter === selectedFilter })}
                onClick={() => changeFilter(filter)}
              >
                {filter.type === 'latest' ? 'Latest' : filter?.value?.name}
              </div>
            )
        )}
        </Carousel>
      {/* </ul> */}
      <div className={styles.filtered_content}>
        <NodeList filter={selectedFilter} numberOfPosts={numberOfPosts} tax={taxonomyMap[selectedFilter?.type]} initialPosts={initialPosts} />
      </div>
      {/* {selectedFilter?.type !== 'latest' && <div className={styles.seemore}>
        <a href={moreLink}>See More</a>
      </div>} */}
    </Container>
  );
}

BlockFilteredList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.array.isRequired,
  initialPosts: PropTypes.array
};
